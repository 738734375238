.news-container {
  flex-shrink: 0;
  margin: 0 auto;
  border-radius: 6px;
  width: 100%;
  max-width: 425px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  /* flex: 0 0 auto; */

  display: grid;
  grid-template-areas:
    "img"
    "headline"
    "description"
    "line"
    "readmore";
  grid-template-rows: min-content max-content auto 1px 40px;

  .news-img {
    grid-area: img;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px 6px 0 0;
    height: 215px;
    padding: 20px;
    background-color: #c4c4c4;
    border-bottom: 1px solid rgb(199, 199, 199);
    .tag {
      padding: 6px;
      display: inline-block;
      min-width: 70px;
      background-color: #007b98;
      border-radius: 16px;
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .news-text {
    padding: 25px;
    line-height: 22px;
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.35rem;
    }
  }
  .line {
    grid-area: line;
    width: 100%;
    border-radius: 16px;
    background-color: #e5e5e5;
  }
  .readMore {
    grid-area: readmore;
    margin-top: 10px;
    height: 24px;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    color: #d0d0d0;
    font-weight: 500;
    text-decoration: none;
  }
  .hiddenTest {
    position: relative;
    top: -400px;
  }
}

.news-container:hover {
  .readMore:hover {
    font-size: 1.8rem;
    font-weight: 600;
    color: #5a5a5a;
  }
}

@media only screen and (max-width: 800px) {
  .news-container {
    height: min-content;
    max-width: 350px;
    .news-img {
      height: 160px;
    }
  }
}
