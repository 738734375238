.about-container {
  padding: 100px 0 100px 0;
  max-width: 1200px;
  margin: 0 auto;

  display: grid;
  grid-template-areas:
    "l t r"
    "l d r";
  grid-template-rows: max-content auto;
  grid-template-columns: auto 58% auto;

  #about-title {
    grid-area: t;
    margin: 0;
    color: var(--primaryColor);
    font-size: 3rem;
    font-weight: 500;
  }
  #about-desc {
    grid-area: d;
    font-size: 1.5rem;
    line-height: 24px;
    max-width: 800px;
    margin: 0 auto;
  }
  img {
    grid-area: r;
    width: 250px;
    height: 230px;
    
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@media only screen and (max-width: 700px) {
  .about-container {
    padding: 20px;
    grid-template-areas:
      "l"
      "t"
      "d";
    grid-template-rows: auto;
    grid-template-columns: auto;
    #about-title {
      padding-top: 30px;
    }
    #about-desc {
      line-height: 24px;
    }
    img {
      grid-area: l;
      width: 100%;
      object-fit: cover;
    }
  }
}
