#footer-container {
  margin-top: 300px;

  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: #2a2a2a;

  #footer-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-template-areas:
      ". . . ."
      "address contact . ."
      "line line line line"
      "copyright . . ."
      ". . . .";
    grid-template-rows: 50px 70px max-content max-content max-content;
    grid-template-columns: max-content auto auto minmax(25%, 50%);
    grid-row-gap: 1em;

    #footer-logo {
      grid-area: logo;
      height: 55px;
      position: absolute;
      bottom: 0;
    }
    #footer-address {
      grid-area: address;
    }
    #footer-contact {
      grid-area: contact;
      margin: 0 0 0 auto;
    }
    #copyright {
      grid-area: copyright;
    }

    p,
    h3 {
      margin: 0;
    }
    p {
      margin-top: 10px;
    }
    a {
      color: white;
      display: block;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  #footer-container {
    #footer-content {
      margin: 0 auto;
      grid-template-areas:
        "address contact ."
        ". . ."
        "line line line"
        "copyright . .";

      grid-template-rows: min-content min-content 50px min-content;
      grid-template-columns: auto;

      #footer-logo {
        width: auto;
        height: 40px;
      }

      #footer-media {
        margin: 0 0 0 auto;
      }
    }
  }
}
