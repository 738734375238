#hero {
  height: 100vh;
  margin: 0 auto;
  background-color: #2A2A2A;
  
  display: grid;

  justify-content: center;
  align-content: center;
  text-align: center;

  .hero-slideshow img {
    position: absolute;
    object-fit: cover;
    object-position: 50% 0%;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    z-index: 0;
    opacity: 1;
    transition: opacity 2.5s ease-in-out;
  }

  #slogan {
    position:relative;
    z-index: 2;
    color: white;
    font-size: 3.8rem;
    font-weight: 600;

    padding-top: 64px;
    text-shadow: 0 0 6px black;
  }
}
/* 
@media only screen and (max-width: 800px) {
  #hero { 
    height: 65vh;

    .hero-slideshow img { 
      height: 65vh;
    }
  }
} */