#home-container {
  min-height: 100vh;
  margin: 0 auto;

  #news {
    .news-section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      max-width: 1200px;
      margin: 100px auto;
      column-gap: 2rem;
      row-gap: 8rem;
      padding: 0 20px;
    }
  }

  #about-section {
    #about {
      position: relative;
      top: -64px;
    }
    h1 {
      margin: 0;
    }
    #about-header {
      padding: 25px;
      font-size: 3.6rem;
      font-weight: 500;
      text-align: center;
      color: white;
      background-color: var(--primaryColor);
    }
  }

  #area {
    padding-top: 14px;
    #area-title {
      width: 100%;
      margin: 50px auto 0 auto;
      background-color: var(--primaryColor);
      position: relative;
      top: 1px;

      h1 {
        font-size: 3.6rem;
        font-weight: 500;
        color: white;
        text-align: center;
        margin: 0;
        padding: 25px;
      }
    }
  }

  #areas {
    background-color: white;
  }

  #useCaseHidden {
    #customer-cases {
      position: relative;
      top: -64px;
    }
    #useCase-header {
      padding: 25px 0;
      margin: 0;
      font-size: 3.6rem;
      font-weight: 500;
      text-align: center;
      color: white;
      background-color: var(--primaryColor);
    }
    .useCase-section {
      padding: 0 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      max-width: 1200px;
      margin: 100px auto;
      column-gap: 2rem;
      row-gap: 8rem;
    }
    .useCase-section::-webkit-scrollbar {
      display: none;
    }
  }

  #contact {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 800px) {
  #home-container {
    #areas {
      margin: unset;
    }
    #news {
      .news-section {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
      }
      .news-section::-webkit-scrollbar {
        display: none;
      }
    }
    #useCaseHidden {
/*       .useCase-section {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
      } */
      .useCase-section::after {
        // this fixes right side padding issue
        content: "";
        padding-right: 0.02px; /* smallest size that is cross browser */
      }
    }
  }
}
