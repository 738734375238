.post {
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto minmax(20vh, auto);
  .post-header {
    grid-area: header;
    padding: 40px 20px;
    position: relative;
    top: 64px;
    min-height: 48vh;
    height: auto;

    background-color: var(--primaryColor);

    display: grid;
    grid-template-areas:
      "."
      "tag"
      "tagText"
      "headline"
      "description"
      ".";
    grid-template-columns: minmax(auto, 1200px);
    grid-template-rows: auto min-content min-content min-content min-content auto;
    justify-content: center;

    h1,
    p {
      width: minmax(auto, 1000px);
      margin: 0;
      color: white;
      word-wrap: break-word;
    }

    .post-tag {
      grid-area: tag;
      background-color: #007b98;
      height: 6px;
      width: 60px;
      border-radius: 16px;
    }

    .post-tagText {
      grid-area: tagText;
      font-size: 2.3rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    .post-headline {
      grid-area: headline;
      font-size: 4rem;
      padding: 60px 0 25px 0;
    }

    .post-text {
      grid-area: description;
      font-size: 2.4rem;
      line-height: 36px;
    }
  }

  .post-container {
    grid-area: content;
    position: relative;
    padding: 0 20px;
    top: 90px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 2rem;

    line-height: 3.2rem;
  }
}

@media only screen and (max-width: 800px) {
  .post {
    .post-header {
      .post-headline {
        line-height: 36px;
      }
      .post-text {
        line-height: 26px;
      }
    }
    .post-container {
      font-size: 1.7rem;
    }
  }
}
